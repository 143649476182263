import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import {
  ContactText,
  Header,
  SubHeader,
  ThankYouContainer,
  ThankYouImage,
  ThankYouInnerContainer,
} from '../../styles';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { useContentfulAssets } from '../../hooks/useContentfulAssets';

const TafcThankYou = () => {
  const data = useStaticQuery(graphql`
    query TafcThankYou {
      allContentfulGeneralPageThankYou(
        filter: { type: { type: { eq: "fleetcardThankYou" } } }
      ) {
        edges {
          node {
            title
            name
            type {
              type
            }
            slug
            node_locale
            sections {
              type
              title
              header
              contentDetails {
                raw
              }
            }
          }
        }
      }
    }
  `);
  const details = data?.allContentfulGeneralPageThankYou?.edges?.map((node) => {
    return node?.node.node_locale;
  });
  const isBrowser = typeof window !== 'undefined';
  const languageList = [...new Set(details)];
  const searchParam = isBrowser ? window.location.search : null;
  const params = new URLSearchParams(searchParam);
  const language = languageList.includes(params?.get('lang'))
    ? params.get('lang')
    : 'en-US';
  const thankYouPageDetails =
    data.allContentfulGeneralPageThankYou.edges.filter((node) => {
      return node?.node?.node_locale === language;
    });
  const thankYouHeader = thankYouPageDetails[0].node.sections.filter(
    (section) => section.type === 'richTextWithMessageBoxYellow'
  )[0];
  const thankYouContent = thankYouPageDetails[0].node.sections.filter(
    (section) => section.type === 'richTextWithParagraph'
  )[0];

  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <ContactText>{children}</ContactText>
      ),
      [BLOCKS.HEADING_1]: (node, children) => <Header>{children}</Header>,
      [BLOCKS.HEADING_2]: (node, children) => <SubHeader>{children}</SubHeader>,
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const asset = useContentfulAssets(node.data.target.sys.id);
        if (asset) {
          return (
            <ThankYouImage
              src={asset?.node?.file?.url}
              alt={asset?.node?.title}
            ></ThankYouImage>
          );
        }
      },
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
  };
  return (
    <>
      <ThankYouContainer className="p-0">
        <ThankYouInnerContainer>
          {thankYouHeader &&
            documentToReactComponents(
              JSON.parse(thankYouHeader.contentDetails.raw),
              optionsMainStyle
            )}
          {thankYouContent &&
            documentToReactComponents(
              JSON.parse(thankYouContent.contentDetails.raw),
              optionsMainStyle
            )}
        </ThankYouInnerContainer>
      </ThankYouContainer>
    </>
  );
};

export default TafcThankYou;
